<template>
    <cluji-card>
        <template #title>
            <h4 class="card-title">Vos slimes</h4>
            <h6 class="card-subtitle text-muted">
                Cette partie du site reprend l'univers du jeu
                <a href="http://slimerancher.com/" target="_blank">Slime rancher</a>
            </h6>
        </template>
        <slime-blocks/>
    </cluji-card>
</template>

<script>
    const SlimeBlocks = () => import('@/components/slime/SlimeBlocks');
    const ClujiCard   = () => import('@/components/ClujiCard');

    export default {
        name: "SlimeRanch",
        components: {SlimeBlocks, ClujiCard}
    }
</script>
